import { z } from 'zod';

const WORKBOOK_RESPONSE_ITEMS_SCHEMA = z.object({
  id: z.number(),
  name: z.string(),
  content: z.string().optional(),
});

export const WORKBOOK_RESPONSE_SCHEMA = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().optional(),
  items: z.array(WORKBOOK_RESPONSE_ITEMS_SCHEMA).optional(),
});

const WORKBOOK_REQUEST_ITEMS_SCHEMA = z.object({
  id: z.number().optional(),
  name: z.string(),
  content: z.string().optional(),
});

export const WORKBOOK_REQUEST_SCHEMA = z.object({
  id: z.number().optional(),
  name: z.string(),
  description: z.string().optional(),
  items: z.array(WORKBOOK_REQUEST_ITEMS_SCHEMA),
});

export const WORKBOOK_WITH_FILES_SCHEMA = WORKBOOK_REQUEST_SCHEMA.extend({
  filenames: z.array(z.string()).nullable().optional(),
  collection_path: z.string().nullable().optional(),
});

export const WORKBOOK_WITH_FILES_RESPONSE_SCHEMA = WORKBOOK_RESPONSE_SCHEMA.extend({
  filenames: z.array(z.string()).nullable().optional(),
  collection_path: z.string().nullable().optional(),
});

export const WORKBOOKS_RESPONSE_ARRAY_SCHEMA = z.array(WORKBOOK_RESPONSE_SCHEMA);
export const WORKBOOKS_WITH_FILES_ARRAY_SCHEMA = z.array(WORKBOOK_WITH_FILES_SCHEMA);
export const WORKBOOKS_WITH_FILES_RESPONSE_ARRAY_SCHEMA = z.array(
  WORKBOOK_WITH_FILES_RESPONSE_SCHEMA
);

export const UPLOAD_WORKBOOK_DOCUMENTS_RESPONSE = z.array(
  z.object({
    namespace: z.string(),
    collection_name: z.string(),
    document_name: z.string(),
  })
);

export type UploadWorkbookDocumentsResponse = z.infer<typeof UPLOAD_WORKBOOK_DOCUMENTS_RESPONSE>;

export type WorkbookRequest = z.infer<typeof WORKBOOK_REQUEST_SCHEMA>;

export type WorkbookRequestItem = z.infer<typeof WORKBOOK_REQUEST_ITEMS_SCHEMA>;

export type WorkbookResponse = z.infer<typeof WORKBOOK_RESPONSE_SCHEMA>;

export type WorkbookWithFiles = z.infer<typeof WORKBOOK_WITH_FILES_SCHEMA>;
export type WorkbookWithFilesResponse = z.infer<typeof WORKBOOK_WITH_FILES_RESPONSE_SCHEMA>;

export type WorkbookResponseItem = z.infer<typeof WORKBOOK_RESPONSE_ITEMS_SCHEMA>;
