import { z } from 'zod';

const defaultApplicationName: string = 'Assistant';
const defaultMaxFileUploadSizeMb: number = 20;
const defaultMaxAudioFileUploadSizeMb: number = 25;
const defaultMaxQuestionLengthChars: number = 1000;
const defaultRequestTimeoutInSeconds: number = 180;
const defaultQuickLinkImprint: string = '';
const defaultQuickLinkPrivacyPolicy: string = '';
const defaultQuickLinkTermsOfUse: string = '';
const defaultQuickLinkPortal: string = '';

export const CONFIG_RESPONSE_SCHEMA = z.object({
  maxFileUploadSizeMB: z.number().min(0).default(defaultMaxFileUploadSizeMb),
  maxAudioFileUploadSizeMB: z.number().min(0).default(defaultMaxAudioFileUploadSizeMb),
  maxQuestionLengthChars: z.number().min(1).default(defaultMaxQuestionLengthChars),
  applicationName: z.string().min(1).default(defaultApplicationName),
  requestTimeoutInSeconds: z.number().min(0).default(defaultRequestTimeoutInSeconds),
  quickLinkImprint: z.string().min(1).default(defaultQuickLinkImprint),
  quickLinkPrivacyPolicy: z.string().min(1).default(defaultQuickLinkPrivacyPolicy),
  quickLinkTermsOfUse: z.string().min(1).default(defaultQuickLinkTermsOfUse),
  quickLinkPortal: z.string().min(1).default(defaultQuickLinkPortal),
});

export type ConfigResponse = z.infer<typeof CONFIG_RESPONSE_SCHEMA>;

export const DEFAULT_CONFIG: ConfigResponse = {
  maxFileUploadSizeMB: defaultMaxFileUploadSizeMb,
  maxAudioFileUploadSizeMB: defaultMaxAudioFileUploadSizeMb,
  maxQuestionLengthChars: defaultMaxQuestionLengthChars,
  applicationName: defaultApplicationName,
  requestTimeoutInSeconds: defaultRequestTimeoutInSeconds,
  quickLinkImprint: defaultQuickLinkImprint,
  quickLinkPrivacyPolicy: defaultQuickLinkPrivacyPolicy,
  quickLinkTermsOfUse: defaultQuickLinkTermsOfUse,
  quickLinkPortal: defaultQuickLinkPortal,
} as const;
