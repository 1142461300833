import { ASSISTANT_SERVICE } from '@/@core/services/api/AssistantService';
import { TRANSCRIPTION_SERVICE } from '@/@core/services/api/TranscriptionService';
import { createPiniaStateSerializer, fileSerializer } from '@/@core/utils/piniaStateSerializers';
import { t } from '@/i18n';
import { TRANSCRIPTION_POLLING_INTERVAL } from '@/modules/transcription/constants';
import { TranscriptionSegment } from '@/modules/transcription/models/TranscriptionContent';
import {
  TranscriptionJob,
  TranscriptionJobStatus,
} from '@/modules/transcription/models/TranscriptionJob';
import { formatSegmentsAsText } from '@/modules/transcription/utils/transcriptionTextFormatUtils';
import { useNotificationStore } from '@/stores/useNotificationStore';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useTranscriptionStore = defineStore(
  'transcription',
  () => {
    const notificationStore = useNotificationStore();

    const transcriptionJob = ref<TranscriptionJob>({
      selectedFile: undefined,
      jobId: undefined,
      transcriptionContent: undefined,
      status: TranscriptionJobStatus.UNINITIALIZED,
    });

    const dataPlatformUrl = ref('');
    const fileId = ref('');
    const pollingHandler = ref<NodeJS.Timeout | undefined>(undefined);

    async function initialize() {
      if (dataPlatformUrl.value === '') {
        const stageUrlResponse = await ASSISTANT_SERVICE.transcriptionDataStageUrl();
        dataPlatformUrl.value = stageUrlResponse.url;
      }
    }

    async function transcribeFile() {
      await handleFileUpload();
      await handleTranscription();
    }

    async function handleFileUpload() {
      if (transcriptionJob.value.selectedFile) {
        transcriptionJob.value.status = TranscriptionJobStatus.UPLOADING;
        await uploadFile(transcriptionJob.value.selectedFile);
      }
    }

    async function uploadFile(file: File) {
      try {
        const uploadFileResponse = await TRANSCRIPTION_SERVICE.uploadFile(dataPlatformUrl.value, {
          sourceData: file,
        });
        fileId.value = uploadFileResponse.fileId;
      } catch (error) {
        transcriptionJob.value.status = TranscriptionJobStatus.FAILED;
        // TODO: differentiate error types (AST-664)
        notificationStore.addErrorNotification(t(`TRANSCRIPTION_NOTIFICATIONS.upload-failed`));
        console.log(error);
      }
    }

    async function handleTranscription() {
      if (
        transcriptionJob.value.selectedFile &&
        transcriptionJob.value.status !== TranscriptionJobStatus.FAILED
      ) {
        transcriptionJob.value.status = TranscriptionJobStatus.TRANSCRIBING;
        await sendTranscriptionRequest(fileId.value);
        await startPollingTranscriptionStatus();
      }
    }

    async function sendTranscriptionRequest(fileId: string) {
      try {
        const transcriptionJobResponse = await ASSISTANT_SERVICE.transcription({
          file_id: fileId,
        });
        transcriptionJob.value.jobId = transcriptionJobResponse.job_id;
      } catch (error) {
        transcriptionJob.value.status = TranscriptionJobStatus.FAILED;
        // TODO: differentiate error types (AST-664)
        notificationStore.addErrorNotification(
          t(`TRANSCRIPTION_NOTIFICATIONS.transcription-failed`)
        );
        console.log(error);
      }
    }

    async function startPollingTranscriptionStatus() {
      if (transcriptionJob.value.status === TranscriptionJobStatus.TRANSCRIBING) {
        await getTranscriptionJobStatus();
        pollingHandler.value = setInterval(() => {
          getTranscriptionJobStatus();
        }, TRANSCRIPTION_POLLING_INTERVAL);
      }
    }

    function stopPollingTranscriptionStatus() {
      if (pollingHandler.value) {
        clearInterval(pollingHandler.value);
      }
      pollingHandler.value = undefined;
    }

    async function getTranscriptionJobStatus() {
      try {
        if (transcriptionJob.value.jobId) {
          const response = await ASSISTANT_SERVICE.transcriptionJobStatus({
            job_id: transcriptionJob.value.jobId,
          });
          if (response.status === 'SUCCESS' && response.transcription) {
            const convertedSegments: TranscriptionSegment[] = response.transcription.map(
              (segment) => ({
                id: segment.id,
                startTime: segment.start_time,
                endTime: segment.end_time,
                text: segment.text,
              })
            );
            transcriptionJob.value.transcriptionContent = {
              segments: convertedSegments,
              formattedText: formatSegmentsAsText(convertedSegments),
            };
            transcriptionJob.value.status = TranscriptionJobStatus.SUCCESSFUL;
            stopPollingTranscriptionStatus();
          } else if (response.status === 'FAILURE') {
            transcriptionJob.value.status = TranscriptionJobStatus.FAILED;
            stopPollingTranscriptionStatus();
            notificationStore.addErrorNotification(
              t(`TRANSCRIPTION_NOTIFICATIONS.transcription-failed`)
            );
          }
        }
      } catch (error) {
        // TODO: differentiate error types (AST-664)
        console.log(error);
      }
    }

    async function clear() {
      transcriptionJob.value = {
        selectedFile: undefined,
        jobId: undefined,
        transcriptionContent: undefined,
        status: TranscriptionJobStatus.UNINITIALIZED,
      };
      await ASSISTANT_SERVICE.transcriptionDelete();
    }

    async function cancelUpload() {
      await clear();
      notificationStore.addInfoNotification(t(`TRANSCRIPTION_NOTIFICATIONS.upload-cancelled`));
    }

    return {
      transcriptionJob,
      transcribeFile,
      clear,
      cancelUpload,
      initialize,
      startPollingTranscriptionStatus,
      stopPollingTranscriptionStatus,
    };
  },
  {
    persist: {
      // we need to display the file's name, but we cannot serialize the entire File object
      serializer: createPiniaStateSerializer({ selectedFile: fileSerializer }),
    },
  }
);
