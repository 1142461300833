import { z } from 'zod';

const COLLECTION_RESOURCE_TYPE_SCHEMA = z.literal('Collection');
const USER_COLLECTION_RESOURCE_TYPE_SCHEMA = z.literal('UserCollection');
const LANGUAGE_BASE_RESOURCE_TYPE_SCHEMA = z.literal('LanguageModel');
const TRANSLATION_RESOURCE_TYPE_SCHEMA = z.literal('MachineTranslation');

const BASE_RESOURCE_SCHEMA = z.object({
  id: z.string().min(1),
  name: z.string().min(1),
});

export const COLLECTION_RESOURCE_SCHEMA = BASE_RESOURCE_SCHEMA.extend({
  type: COLLECTION_RESOURCE_TYPE_SCHEMA,
});
export type CollectionResource = z.infer<typeof COLLECTION_RESOURCE_SCHEMA>;

export const USER_COLLECTION_RESOURCE_SCHEMA = BASE_RESOURCE_SCHEMA.extend({
  type: USER_COLLECTION_RESOURCE_TYPE_SCHEMA,
});
export type UserCollectionResource = z.infer<typeof USER_COLLECTION_RESOURCE_SCHEMA>;

export const LANGUAGE_MODEL_RESOURCE_SCHEMA = BASE_RESOURCE_SCHEMA.extend({
  type: LANGUAGE_BASE_RESOURCE_TYPE_SCHEMA,
});
export type LanguageModelResource = z.infer<typeof LANGUAGE_MODEL_RESOURCE_SCHEMA>;

export const TRANSLATION_RESOURCE_SCHEMA = BASE_RESOURCE_SCHEMA.extend({
  type: TRANSLATION_RESOURCE_TYPE_SCHEMA,
  languages: z.record(z.string(), z.array(z.string())),
});
export type TranslationResource = z.infer<typeof TRANSLATION_RESOURCE_SCHEMA>;

const RESOURCE_SCHEMA = z.discriminatedUnion('type', [
  COLLECTION_RESOURCE_SCHEMA,
  USER_COLLECTION_RESOURCE_SCHEMA,
  LANGUAGE_MODEL_RESOURCE_SCHEMA,
  TRANSLATION_RESOURCE_SCHEMA,
]);
export type Resource = z.infer<typeof RESOURCE_SCHEMA>;

export const RESOURCES_RESPONSE_SCHEMA = z.array(RESOURCE_SCHEMA);
export type ResourcesResponse = z.infer<typeof RESOURCES_RESPONSE_SCHEMA>;

export interface GroupedResources {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Collection?: CollectionResource[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  UserCollection?: UserCollectionResource[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LanguageModel?: LanguageModelResource[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  MachineTranslation?: TranslationResource[];
}
