import { FeatureFlag } from '@/@core/models/FeatureFlags';
import { useFeatureFlag } from '@/@core/services/feature-flag';
import { homepageRoute } from '@/modules/Homepage/route';
import { translationRoute } from '@/modules/Translation/route';
import { useTranslationResourcesStore } from '@/modules/Translation/stores/useTranslationResourcesStore';
import { useTranscriptionStore } from '@/modules/transcription/stores/useTranscriptionStore';
import { useConfigStore } from '@/stores/useConfigStore';
import { NavigationGuard } from 'vue-router';

export async function withTranslationResources() {
  await useTranslationResourcesStore().getTranslationResources();
}

export async function withTranscriptionResources() {
  await useTranscriptionStore().initialize();
}

export async function withConfig() {
  const configStore = useConfigStore();
  if (!configStore.config) {
    await configStore.getConfig();
  }
}

export const redirectToTranslationRouteIfHomepageDisabled: NavigationGuard = (to) => {
  if (
    to.path === homepageRoute.path &&
    !useFeatureFlag(FeatureFlag.ENABLE_HOMEPAGE, false) &&
    !useFeatureFlag(FeatureFlag.ENABLE_ALTERNATE_HOME, false)
  ) {
    return { path: translationRoute.path };
  }
};
