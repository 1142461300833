export interface FeatureFlags {
  enableHomepage?: boolean;
  enableSummarize?: boolean;
  enableQa?: boolean;
  enableTranslate?: boolean;
  enableTranscribe?: boolean;
  enableUsecases?: boolean;
  enableSearch?: boolean;
  enableAlternateHome?: boolean;
  enableTellJoke?: boolean;
  enableCockpit?: boolean;
}
export enum FeatureFlag {
  ENABLE_HOMEPAGE = 'enableHomepage',
  ENABLE_SUMMARIZE = 'enableSummarize',
  ENABLE_QA = 'enableQa',
  ENABLE_TRANSLATE = 'enableTranslate',
  ENABLE_TRANSCRIBE = 'enableTranscribe',
  ENABLE_USECASES = 'enableUsecases',
  ENABLE_SEARCH = 'enableSearch',
  ENABLE_ALTERNATE_HOME = 'enableAlternateHome',
  ENABLE_TELL_JOKE = 'enableTellJoke',
  ENABLE_COCKPIT = 'enableCockpit',
}
